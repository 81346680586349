import { useEffect, type ReactElement, type ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';

import '@module-federation/nextjs-mf/src/include-defaults';

// css from third party libs
import 'antd/dist/antd.less';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// css for @stockbit/ui
import 'assets/iconmoon/iconmoon.css';
import 'assets/reactAutoSuggest/overide.css';
import 'assets/masonry/masonry.css';

const MainApp = dynamic(() => import('global/MainApp'));

export type NextPageProps<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  /** Pass true to skip MainApp */
  isWebView?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageProps;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const { getLayout = (page) => page, isWebView } = Component;

  const renderComponent = () => getLayout(<Component {...pageProps} />);

  return (
    <>
      {isWebView ? (
        renderComponent()
      ) : (
        <MainApp>{renderComponent()}</MainApp>
      )}
    </>
  );
}

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   console.log(metric);
// }

export default App;
